import * as React from "react";
import { Dispatch, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Popover } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

import { getMenuNameFromPath, getSubMenuFromMainMenu, isSubMenuActive } from "../components/common";
import logo from "../assets/images/logo-tilted-small.png";

import { iStoreAction } from "../redux/reducers";
import { AppDispatchContext, AppStateContext } from "../App";
import { Add, ExpandMore, OpenInNew } from "@material-ui/icons";
import banner from "../assets/images/bannerSide.png";
import playStore from "../assets/images/playStore.png";
import BannerSide from "../components/Card/BannerCardSideBar";

function Sidebar(props: any): any {
    const location = useLocation();
    const opened_menu: string = getMenuNameFromPath(location.pathname, 1);
    const links = getSubMenuFromMainMenu(opened_menu);
    const logoImage = { width: '60%', marginTop: '-24px' };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { shop, shops } = useContext(AppStateContext);

    const dispatch: Dispatch<iStoreAction> = useContext(AppDispatchContext);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {

        setAnchorEl(null);
    };

    function ListItemLink(props: any) {
        return <ListItem button component={Link} {...props} />;
    }
    return <div className="wrapper">
        <div id="sidebar">
            <Row>
                <Col md={12} xs={12} className="d-flex align-items-center justify-content-center logo-parent-div">
                    <Row className="logo-row ">
                        <Col>
                            <div className="sidebar-header">
                                <div className="logo" >
                                    <img src={logo} alt="Emailwish Logo" style={logoImage} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={12} xs={12} className="d-flex align-items-center justify-content-center dropdown-parent-div">
                    <div className={"selector-div mt-3"}>
                        <div className="selected_dropdown">
                            <div className="selected_value__name">
                                {shop && shop.name}
                            </div>
                            <div className="selected_value__change_icon">
                                <IconButton
                                    color="primary"
                                    aria-label="Help"
                                    aria-controls="Help-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    component="span">
                                    <ExpandMore color="action" />
                                </IconButton>
                            </div>

                        </div>

                        {
                            shop && <Popover
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                style={{
                                    boxShadow: "var(--box-shadow-low)"
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                onClose={handleClose}
                            >
                                <div className="app-popover_wrapper">
                                    <List component="nav" className="app-popover">
                                        {
                                            shops && shops.map((shop, index) => {
                                                return <ListItem button className="app-popover_item" key={index}
                                                    onClick={() => {
                                                        dispatch({
                                                            type: "set_active_shop",
                                                            shop: shop
                                                        })
                                                    }}>
                                                    {shop.name}
                                                    {" "}
                                                    <a
                                                        href={`https://${shop.myshopify_domain}`}
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                    >
                                                        <OpenInNew />
                                                    </a>
                                                </ListItem>
                                            })
                                        }
                                        <a href="https://apps.shopify.com/emailmarketing_emailwish_abandonedcart_popup_chat_reviews"
                                            target="_blank" rel="noopener noreferrer">
                                            <ListItem button className="app-popover_item">
                                                <Add color="action" />Add New Shop
                                            </ListItem>
                                        </a>
                                    </List>
                                </div>
                            </Popover>
                        }
                        {/*<Select required={true}*/}
                        {/*        name="shops-selector"*/}
                        {/*        options={shops && shops.map(s => ({value: s.id, label: s.name}))}*/}
                        {/*        value={shop && {value: shop.id, label: shop.name}}*/}
                        {/*        onChange={(e: any) => {*/}
                        {/*            if(shops){*/}
                        {/*                const selected: any = shops.find(s => s.id === parseInt(e.value));*/}
                        {/*                dispatch({*/}
                        {/*                    type: "set_active_shop", shop: selected*/}
                        {/*                });*/}
                        {/*            }*/}

                        {/*        }}*/}
                        {/*/>*/}
                    </div>
                </Col>
                <Col md={12} xs={12} className="d-flex align-items-center justify-content-center menu-parent-div">
                    <div className="list-col pl-3 w-100">
                        <div className="vertical-center w-100">
                            <List>
                                {links.map((i, index) => {
                                    let isActive = isSubMenuActive(location.pathname, i.link);
                                    return (
                                        <ListItemLink
                                            className={"sub-menu " + (isActive ? "active" : "")}
                                            key={index}
                                            to={i.link}
                                            data-tut={i["data-tut"]}
                                        >
                                            <ListItemIcon
                                                style={
                                                    isActive
                                                        ? {
                                                            minWidth: "40px",
                                                            minHeight: "40px",
                                                            background: "black",
                                                            color: "white",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            borderRadius: "10px",
                                                            marginRight:"10px",
                                                            boxShadow: "5px 15px 12px rgba(0, 0, 0, 0.2)",
                                                        }
                                                        : {
                                                            minWidth: "40px", // Apply default styling if not active
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }
                                                }
                                            >
                                                {isActive && i.active_node}
                                                {(!isActive || !i.active_node) && i.svg_node}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <div
                                                        className={"list-text"}
                                                        style={{
                                                            width: 90,
                                                            paddingLeft: "4px",
                                                            whiteSpace: "initial",
                                                            color: "black",
                                                        }}
                                                    >
                                                        {i.text}
                                                    </div>
                                                }
                                            />
                                        </ListItemLink>
                                    );
                                })}
                            </List>

                        </div>
                    </div>
                </Col>
                {/* </Row> 
                <Row> */}

                {location.pathname === '/chats' && (
                    <Col md={12} xs={12} className="d-flex align-items-center justify-content-center banner-parent-div">
                        <div className={"selector-div mt-3"}>
                            <div className="selected_dropdown">
                                <BannerSide style={{ cursor: 'pointer' }}>
                                    <img className="rounded-lg" src={banner} style={{ width: '100%;' }} alt="banner" />
                                    <div style={{ display: 'block', padding: '10px' }}>
                                        <p className="text-white" style={{ textAlign: 'center' }}>
                                            Chat With Customers on your Phone
                                        </p>
                                        <a href="https://play.google.com/store/apps/details?id=com.emailwish.app" target="__blank" onClick={(e) => e.stopPropagation()} ><img className="rounded-lg" src={playStore} style={{ width: '100%;' }} alt="banner" /></a>
                                    </div>

                                </BannerSide>
                            </div>
                        </div>
                    </Col>
                )}
            </Row>
        </div>
        <div id="content">
            {
                props.children
            }
        </div>

    </div>;
}

export default Sidebar;
